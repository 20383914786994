import {
  SHOW_ACTIONS_INVENTORY,
  HIDE_ACTIONS_INVENTORY,
  RESET_DASBOARD,
  PREGUNTA_REPORTE,
  FILAS_POR_PAGINA,
  CANTIDAD_PAGINAS,
  PAGINA_ACTUAL,
  TEXTO_BUSCAR,
  CLEAN_PAGINA_ACTUAL,
  RUTA_ACTUAL,
  ABRIR_MODAL,
  CERRAR_MODAL,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case SHOW_ACTIONS_INVENTORY:
      return {
        ...state,
        showactionsinventory: true,
        hideactionsinventory: false,
      };
    case HIDE_ACTIONS_INVENTORY:
      return {
        ...state,
        showactionsinventory: false,
        hideactionsinventory: true,
      };
    case RESET_DASBOARD:
      return {
        ...state,
        showactionsinventory: false,
        hideactionsinventory: true,
        preguntareporte: false,
      };
    case PREGUNTA_REPORTE:
      return {
        ...state,
        preguntareporte: true,
      };
    case FILAS_POR_PAGINA:
      return {
        ...state,
        filasporpagina: action.payload,
      };
    case PAGINA_ACTUAL:
      return {
        ...state,
        paginaseleccionada: action.payload,
      };
    case CLEAN_PAGINA_ACTUAL:
      return {
        ...state,
        paginaseleccionada: null,
      };
    case CANTIDAD_PAGINAS:
      return {
        ...state,
        cantidadpaginas: action.payload,
      };
    case TEXTO_BUSCAR:
      return {
        ...state,
        textobuscar: action.payload,
      };

    case RUTA_ACTUAL:
      return {
        ...state,
        rutaactualglobal: action.payload,
      };

    case CERRAR_MODAL:
      return {
        ...state,
        estadoModal: false,
      };
    case ABRIR_MODAL:
      return {
        ...state,
        estadoModal: true,
      };
    default:
      return state;
  }
};
