import {
  ABRIR_MODAL,
  CERRAR_MODAL,
  CODIGO_ERROR,
  CODIGO_ACTUAL,
  LISTADO_CODIGOS,
  ELIMINAR_CODIGO,
  REGISTRAR_CODIGO,
  FORMULARIO_CODIGO,
  ACTUALIZAR_CODIGO,
  CLEAN_STATUS_RESPUESTA,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_CODIGO:
      return {
        ...state,
        formulario: true,
      };
    case LISTADO_CODIGOS:
      return {
        ...state,
        codigos: action.payload,
      };

    case REGISTRAR_CODIGO:
      return {
        ...state,
        codigos: [...state.codigos, action.payload.data],
        formulario: false,
        errorformulario: false,
        statusRespuesta: action.payload.status,
      };

    case ACTUALIZAR_CODIGO:
      return {
        ...state,
        codigos: state.codigos.map((codigo) =>
          codigo.id === action.payload.id ? action.payload : codigo
        ),
        estadoModal: true,
        statusRespuesta: action.payload.status,
      };

    case CLEAN_STATUS_RESPUESTA:
      return {
        statusRespuesta: action.payload,
      };

    case ELIMINAR_CODIGO:
      return {
        ...state,
        codigos: state.codigos.filter((codigo) => codigo.id !== action.payload),
        codigoSeleccionado: null,
      };

    case CODIGO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    case CODIGO_ACTUAL:
      return {
        ...state,
        codigoSeleccionado: action.payload,
      };

    case ABRIR_MODAL:
      return {
        ...state,
        estadoModal: true,
        tipoModal:
          action.payload === "tipoAgregar"
            ? "tipoAgregar"
            : action.payload === "tipoDetalles"
            ? "tipoDetalles"
            : action.payload === "tipoEditar"
            ? "tipoEditar"
            : null,
        statusRespuesta: null,
      };

    case CERRAR_MODAL:
      return {
        ...state,
        estadoModal: false,
        statusRespuesta: null,
        tipoModal: null,
      };

    default:
      return state;
  }
};
