import React, { useReducer, useContext } from "react";
import ArticuloContext from "./articuloContext";
import ArticuloReducer from "./articuloReducer";

import {
  // LISTADO_ARTICULOS,
  REGISTRAR_ARTICULO,
  ARTICULO_ERROR,
  FORMULARIO_ARTICULO,
  ELIMINAR_ARTICULO,
  ARTICULO_ACTUAL,
  INVENTARIO_USUARIO,
  VINCULAR_ARTICULOS,
  GENERAR_REPORTE_ARTICULO,
  LIMPIAR_GENERAR_REPORTE_ARTICULO,
  ARTICULOS_DISPONIBLES,
  ACTUALIZAR_ARTICULO,
  DESVINCULAR_ARTICULOS,
  CLEAN_STATUS,
  GENERAR_REPORTE_INVENTARIO,
  LIMPIAR_GENERAR_REPORTE_INVENTARIO,
} from "../../types";
import clienteAxios from "../../config/axios";
import swal from "sweetalert";
import ColegioContext from "../colegios/colegioContext";

const ArticuloState = (props) => {
  const initialState = {
    formulario: false,
    errorformulario: false,
    articulos: [],
    articulo: null,
    articuloSeleccionado: null,
    inventariousuario: [],
    articulosvinculados: [],
    articulosdesvinculados: [],
    articulosdisponibles: [],
    reporte: null,
    articuloeditado: null,
    eliminarStatus: null,
    statusAgregar: null,
    estadoModal: false,
    resultadoFiltro: [],
    statusFiltro: null,
  };

  const colegiosContext = useContext(ColegioContext);

  const {
    inventariocolegio,
    detallescolegioseleccionado,
    colegioInventario,
    colegioInventarioDisponible,
  } = colegiosContext;

  const [state, dispatch] = useReducer(ArticuloReducer, initialState);

  // Funciones
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_ARTICULO,
    });
  };

  const vincularActiculoSeleccionado = (id, estado) => {
    dispatch({
      type: VINCULAR_ARTICULOS,
      payload: id,
      estado: estado,
    });
  };

  const desvincularArticuloSeleccionado = (id) => {
    dispatch({
      type: DESVINCULAR_ARTICULOS,
      payload: id,
    });
  };

  // Lista de articulos
  // const listaArticulos = async () => {
  //   try {
  //     const respuesta = await clienteAxios.get("/api/articulos");
  //     dispatch({
  //       type: LISTADO_ARTICULOS,
  //       payload: respuesta.data,
  //     });
  //   } catch (error) {}
  // };

  // Lista de articulos
  const articulosDisponibles = async (id) => {
    try {
      const respuesta = await clienteAxios.get(
        `/api/articulosDisponibles/${id}`
      );
      dispatch({
        type: ARTICULOS_DISPONIBLES,
        payload: respuesta.data,
      });
    } catch (error) {}
  };

  const cleanStatusArticulo = async (id) => {
    dispatch({
      type: CLEAN_STATUS,
    });
  };

  // INVENTARIO POR ID
  const inventarioUsuario = async (id) => {
    try {
      const respuesta = await clienteAxios.get(`/api/articulosU/${id}`);
      dispatch({
        type: INVENTARIO_USUARIO,
        payload: respuesta.data,
      });
    } catch (error) {}
  };

  // EDITAR UN ARTICULO
  const actualizarArticulo = async (articulo) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Está a punto de modificar un artículo!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Modificar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        const resultado = await clienteAxios.put(
          `/api/articulos/${articulo.id}`,
          articulo
        );
        if (willDelete) {
          dispatch({
            type: ACTUALIZAR_ARTICULO,
            payload: resultado.data,
            articuloeditado: resultado.status,
          });
          colegioInventario(10, 0, articulo.sucursal_articulo);
          swal({
            title: `${resultado.data === "ok" ? "Artículo modificado!" : ""}`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: "Hubo un error, intente nuevamente",
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN EDITAR UN ARTICULO

  // GENERAR PDF POR CODIGO
  const generarReporteArticulo = async (codigo) => {
    try {
      swal({
        title: `Se generara su reporte en breve, espere un momento`,
        text: "Estamos procesando su reporte...",
        icon: "info",
        button: true,
      });
      const resultado = await clienteAxios.post("/api/articulospdf", codigo);
      // Insertar la articulo en el state
      dispatch({
        type: GENERAR_REPORTE_ARTICULO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = swal({
        title: `${
          error.response.data === "No hay articulos asociados a ese codigo"
            ? "El código ingresado no existe"
            : error.response.data
        }`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: ARTICULO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN GENERAR PDF POR CODIGO

  // GENERAR PDF CON TODO EL INVENTARIO
  const generarReporteInventario = async (unidad) => {
    try {
      swal({
        title: `Se generará su reporte en breve, espere un momento`,
        text: "Estamos procesando su reporte...",
        icon: "info",
        button: true,
      });
      const resultado = await clienteAxios.get(
        `/api/generarexcel/${unidad}`
      );

      // Insertar la articulo en el state
      dispatch({
        type: GENERAR_REPORTE_INVENTARIO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = swal({
        title: `${
          error.response.data === "No posee articulos la unidad educativa"
            ? "La unidad educativa no posee artículos"
            : error.response.data
        }`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: ARTICULO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN GENERAR PDF CON TODO EL INVENTARIO

  const limpiarGenerarReporteArticulo = () => {
    dispatch({
      type: LIMPIAR_GENERAR_REPORTE_ARTICULO,
    });
  };

  const limpiarGenerarReporteInventario = () => {
    dispatch({
      type: LIMPIAR_GENERAR_REPORTE_INVENTARIO,
    });
  };

  // AGREGAR NUEVO ARTICULO
  const agregarArticulo = async (articulo) => {
    try {
      const resultado = await clienteAxios.post("/api/articulos", articulo);
      const alerta = swal({
        title: "¡Artículo Añadido!",
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar el articulo en el state
      dispatch({
        type: REGISTRAR_ARTICULO,
        payload: resultado.data,
        statusAgregar: resultado.status,
        alerta,
      });
      colegioInventario(10, 0, articulo.sucursal_articulo);
    } catch (error) {
      const alerta = swal({
        title: `Hubo un error, intente nuevamente completando los campos requeridos`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2500,
        button: false,
      });
      dispatch({
        type: ARTICULO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVO ARTICULO

  // ELIMINAR UN ARTICULO
  const eliminarArticulo = async (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const resultado = await clienteAxios.delete(`/api/articulos/${id}`);
          dispatch({
            type: ELIMINAR_ARTICULO,
            payload: id,
            eliminarStatus: resultado.status,
          });
          swal({
            title: `¡Eliminado!`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          colegioInventario(10, 0, id);
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `${error.response.data}`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN ELIMINAR UN ARTICULO

  // GUARDAR ARTICULO SELECCIONADO
  const guardarArticuloActual = (articulo) => {
    dispatch({
      type: ARTICULO_ACTUAL,
      payload: articulo,
    });
  };
  // FIN GUARDAR ARTICULO SELECCIONADO

  return (
    <ArticuloContext.Provider
      value={{
        articulosdesvinculados: state.articulosdesvinculados,
        articuloSeleccionado: state.articuloSeleccionado,
        articulosdisponibles: state.articulosdisponibles,
        articulosvinculados: state.articulosvinculados,
        inventariousuario: state.inventariousuario,
        resultadoFiltro: state.resultadoFiltro,
        articuloeditado: state.articuloeditado,
        errorformulario: state.errorformulario,
        eliminarStatus: state.eliminarStatus,
        statusAgregar: state.statusAgregar,
        statusFiltro: state.statusFiltro,
        estadoModal: state.estadoModal,
        formulario: state.formulario,
        articulos: state.articulos,
        articulo: state.articulo,
        reporte: state.reporte,
        // listaArticulos,
        agregarArticulo,
        eliminarArticulo,
        inventarioUsuario,
        mostrarFormulario,
        actualizarArticulo,
        cleanStatusArticulo,
        articulosDisponibles,
        guardarArticuloActual,
        generarReporteArticulo,
        generarReporteInventario,
        vincularActiculoSeleccionado,
        limpiarGenerarReporteArticulo,
        limpiarGenerarReporteInventario,
        desvincularArticuloSeleccionado,
      }}
    >
      {props.children}
    </ArticuloContext.Provider>
  );
};

export default ArticuloState;
