import React, { useReducer } from "react";
import UsuarioContext from "./usuarioContext";
import UsuarioReducer from "./usuarioReducer";
import clienteAxios from "../../config/axios";

import {
  OBTENER_USUARIO,
  USUARIO_ERROR,
  REGISTRAR_USUARIO,
  VALIDAR_FORMULARIO,
  FORMULARIO_USUARIO,
  ELIMINAR_USUARIO,
  ACTUALIZAR_USUARIO,
  USUARIO_ACTUAL,
  ENVIAR_CONTRASEÑA,
  VINCULAR_USUARIO_COLEGIOS,
  DETALLES_USUARIO,
  CREAR_CUENTA,
  ABRIR_MODAL,
  CERRAR_MODAL,
} from "../../types";
import swal from "sweetalert";

const UsuarioState = (props) => {
  const initialState = {
    usuarios: [],
    nuevaCuenta: null,
    formulario: false,
    errorformulario: false,
    usuario: null,
    mensaje: null,
    usuarioSeleccionado: null,
    idContraseña: null,
    datosVinculacion: [],
    empresasDesvincular: [],
    detallesusuario: null,
    statusActualizar: null,
    estadoModal: false,
    statusRespuesta: null,
    tipoModal: null,
  };

  // Crear dispatch y state
  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  // Serie de funciones para el CRUD

  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_USUARIO,
    });
  };

  // Obtener Usuarios
  const obtenerUsuarios = async () => {
    try {
      const resultado = await clienteAxios.get("/api/users");
      dispatch({
        type: OBTENER_USUARIO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };

  // NUEVO CUENTA
  const nuevaCuentaRegistrada = async (usuario) => {
    try {
      const resultado = await clienteAxios.post("/api/users", usuario);

      // Insertar el usuario en el state
      dispatch({
        type: CREAR_CUENTA,
        payload: resultado.data,
      });
      obtenerUsuarios();
    } catch (error) {
      const alerta = swal({
        title: `${error.response.data}`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN NUEVO CUENTA

  // AGREGAR NUEVO USUARIO
  const agregarUsuario = async (usuario) => {
    try {
      const resultado = await clienteAxios.post("/api/users", usuario);
      const alerta = swal({
        title: "Usuario creado correctamente",
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar el usuario en el state
      dispatch({
        type: REGISTRAR_USUARIO,
        payload: resultado,
        alerta,
      });
      obtenerUsuarios();
    } catch (error) {
      const alerta = swal({
        title: `Hubo un error`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVO USUARIO

  // ELIMINAR UN USUARIO
  const eliminarUsuario = (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/users/${id}`);
          dispatch({
            type: ELIMINAR_USUARIO,
            payload: id,
          });
          swal({
            title: `¡Usuario eliminado!`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Hubo un error`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN ELIMINAR UN USUARIO

  // ENVIAR CONTRASEÑA AL CORREO
  const enviarContraseña = async (correo) => {
    try {
      const resultado = await clienteAxios.post("/api/setPass", {
        email: correo,
      });
      dispatch({
        type: ENVIAR_CONTRASEÑA,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };

      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN ENVIAR CONTRASEÑA AL CORREO

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });
  };

  // DETALLES USUARIO
  const detallesUsuario = async (id) => {
    try {
      const resultado = await clienteAxios.get(`/api/users/${id}`);
      dispatch({
        type: DETALLES_USUARIO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };

  // EDITAR UN USUARIO
  const actualizarUsuario = async (usuario) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Está a punto de modificar un usuario!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Modificar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        const resultado = await clienteAxios.put(
          `/api/users/${usuario.id}`,
          usuario
        );
        if (willDelete && resultado.status === 200) {
          dispatch({
            type: ACTUALIZAR_USUARIO,
            payload: resultado,
          });

          swal({
            title: `${resultado.data === "ok" ? "¡Usuario modificado!" : ""}`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          obtenerUsuarios();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: "Hubo un error, intente nuevamente",
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN EDITAR UN USUARIO

  // GUARDAR USUARIO SELECCIONADO
  const guardarUsuarioActual = (usuario) => {
    dispatch({
      type: USUARIO_ACTUAL,
      payload: usuario,
    });
  };
  // FIN GUARDAR USUARIO SELECCIONADO

  // VINCULAR USUARIO A EMPRESA
  const vincularUsuarioColegio = async (datos) => {
    try {
      const resultado = await clienteAxios.post("/api/userEmpresa", datos);
      const alerta = swal({
        title: `${resultado.data}`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar el usuario en el state
      dispatch({
        type: VINCULAR_USUARIO_COLEGIOS,
        payload: resultado.data,
        alerta,
      });
      obtenerUsuarios();
    } catch (error) {
      const alerta = swal({
        title: `${error.response.data}`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: USUARIO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN VINCULAR USUARIO A EMPRESA

  // DESVINCULAR USUARIO A EMPRESA
  const desvincularUsuarioColegio = async (datos) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podrá recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete("/api/desligarUserEmpresa", {
            data: datos,
          });
          swal({
            title: "¡Usario Eliminado!",
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Hubo un error, intente nuevamente`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN VINCULAR USUARIO A EMPRESA

  const abrirModal = (categoriaModal) => {
    dispatch({
      type: ABRIR_MODAL,
      payload: categoriaModal,
    });
  };
  const cerrarModal = () => {
    dispatch({
      type: CERRAR_MODAL,
    });
  };

  return (
    <UsuarioContext.Provider
      value={{
        empresasDesvincular: state.empresasDesvincular,
        usuarioSeleccionado: state.usuarioSeleccionado,
        statusActualizar: state.statusActualizar,
        datosVinculacion: state.datosVinculacion,
        detallesusuario: state.detallesusuario,
        statusRespuesta: state.statusRespuesta,
        errorformulario: state.errorformulario,
        idContraseña: state.idContraseña,
        nuevaCuenta: state.nuevaCuenta,
        estadoModal: state.estadoModal,
        formulario: state.formulario,
        tipoModal: state.tipoModal,
        usuarios: state.usuarios,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cerrarModal,
        abrirModal,
        mostrarError,
        agregarUsuario,
        eliminarUsuario,
        detallesUsuario,
        obtenerUsuarios,
        enviarContraseña,
        actualizarUsuario,
        mostrarFormulario,
        guardarUsuarioActual,
        nuevaCuentaRegistrada,
        vincularUsuarioColegio,
        desvincularUsuarioColegio,
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;
