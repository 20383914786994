import {
  LISTADO_ARTICULOS,
  REGISTRAR_ARTICULO,
  ARTICULO_ERROR,
  FORMULARIO_ARTICULO,
  ELIMINAR_ARTICULO,
  ARTICULO_ACTUAL,
  INVENTARIO_USUARIO,
  VINCULAR_ARTICULOS,
  GENERAR_REPORTE_ARTICULO,
  LIMPIAR_GENERAR_REPORTE_ARTICULO,
  ARTICULOS_DISPONIBLES,
  ACTUALIZAR_ARTICULO,
  CLEAN_STATUS,
  GENERAR_REPORTE_INVENTARIO,
  LIMPIAR_GENERAR_REPORTE_INVENTARIO,
  
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case VINCULAR_ARTICULOS:
      return {
        ...state,
        articulosvinculados: state.articulosvinculados.concat(action.payload),
      };

    case GENERAR_REPORTE_ARTICULO:
    case GENERAR_REPORTE_INVENTARIO:
      return {
        ...state,
        reporte: action.payload,
      };

    case LIMPIAR_GENERAR_REPORTE_ARTICULO:
    case LIMPIAR_GENERAR_REPORTE_INVENTARIO:
      return {
        ...state,
        reporte: null,
      };

    case FORMULARIO_ARTICULO:
      return {
        ...state,
        formulario: true,
      };
    case LISTADO_ARTICULOS:
      return {
        ...state,
        articulos: action.payload,
      };

    case ARTICULOS_DISPONIBLES:
      return {
        ...state,
        articulosdisponibles: action.payload,
      };

    case REGISTRAR_ARTICULO:
      return {
        ...state,
        articulos: [...state.articulos, action.payload],
        formulario: false,
        errorformulario: false,
        statusAgregar: action.statusAgregar,
      };
    case ELIMINAR_ARTICULO:
      return {
        ...state,
        articulos: state.articulos.filter(
          (articulo) => articulo.id !== action.payload
        ),
        articuloSeleccionado: null,
        eliminarStatus: action.eliminarStatus,
      };
    case INVENTARIO_USUARIO:
      return {
        ...state,
        inventariousuario: action.payload,
      };
    case ARTICULO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    case ARTICULO_ACTUAL:
      return {
        ...state,
        articuloSeleccionado: action.payload,
      };
    case ACTUALIZAR_ARTICULO:
      return {
        ...state,
        articulos: state.articulos.map((articulo) =>
          articulo.id === action.payload.id ? action.payload : articulo
        ),
        articuloeditado: action.articuloeditado,
        estadoModal: true,
      };
    case CLEAN_STATUS:
      return {
        ...state,
        articuloeditado: null,
        eliminarStatus: null,
        statusAgregar: null,
        estadoModal: null,
      };
    default:
      return state;
  }
};
