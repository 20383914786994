import React, { useReducer } from "react";
import ProyectoContext from "./proyectoContext";
import proyectoReducer from "./proyectoReducer";

import {
  DATOS_ORDEN,
  MODULOS_EVENTO,
  ORDENES_EVENTO,
  LISTADO_EVENTOS,
  LISTADO_CLIENTES,
  EVENTOS_POR_FECHA,
  PROVEEDORES_EVENTO,
  LISTADO_PROOVEDORES,
  LECTURA_ORDEN_COMPRA,
  CLEAN_STATUS_MODULOS,
  ACTIVAR_ORDEN_COMPRA,
  GENERAR_PDF_PROPUESTA,
  COMPRAS_VENTAS_EVENTOS,
  DESACTIVAR_ORDEN_COMPRA,
  CLEAN_STATUS_MODULOS_ORDEN,
  CLEAN_STATUS_COMPRAS_VENTAS,
  CLEAN_GENERAR_REPORTE_ORDEN,
  TOTAL_VENTAS_COMPRAS_EVENTO,
  CLEAN_STATUS_ORDENES_EVENTO,
  MODULOS_ORDEN_PROVEEDOR_EVENTO,
  CLEAN_GENERAR_REPORTE_PROPUESTA,
  CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO,
  CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT,
  CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR,
} from "../../types";

import clienteAxios from "../../config/axios";
import swal from "sweetalert";

const ProyectoState = (props) => {
  const initialState = {
    eventos: [],
    clientes: [],
    proveedores: [],
    contabilidad: "",
    modulosEvento: [],
    modulosOrden: [],
    stGenerarPresupuesto: false,
    stGenerarOrden: false,
    eventosPorFecha: [],
    ordenesPorFecha: [],
    modulosProveedorEvento: [],
    statusModuloOrden: null,
    datosOrden: [],
    stActivarOrdenCompra: false,
    datosLecturaOrden: {},
    stComprasVentasEventos: null,
    stStatusComprasVentas: null,
    stTotalComprasVentasEvento: null,
    stProveedoresEvento: [],
    stStatusTotalIngEgEvento: null,
    stOrdenesEvento: [],
    stStatusfnGetOrdenesEvento: null,
    stStatusfnGetProveedoresForIdEvent: null,
  };

  const [state, dispatch] = useReducer(proyectoReducer, initialState);

  const flagGenerarPresupuesto = () => {
    dispatch({
      type: GENERAR_PDF_PROPUESTA,
    });
  };

  const datosOrdenGlobal = (datos) => {
    dispatch({
      type: LECTURA_ORDEN_COMPRA,
      payload: datos,
    });
  };

  const fnComprasVentasEventos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/getComprasVentasEventos");
      dispatch({
        type: COMPRAS_VENTAS_EVENTOS,
        payload: respuesta.data,
        status: respuesta.status,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const fnCleanStatusComprasVentasEventos = () => {
    dispatch({
      type: CLEAN_STATUS_COMPRAS_VENTAS,
    });
  };

  const fnTotalComprasVentasEvento = async (idsEvento, tipoFact) => {
    try {
      const respuesta = await clienteAxios.get(
        `/api/getEventoTotal/${idsEvento}`
      );
      swal({
        title: `Resumen proyecto`,
        text: `Ingresos $ ${new Intl.NumberFormat("de-DE").format(
          Math.trunc(respuesta.data.ventas)
        )} \n Egresos $ ${new Intl.NumberFormat("de-DE").format(
          Math.trunc(respuesta.data.compras)
        )} \n Utilidad $ ${new Intl.NumberFormat("de-DE").format(
          Math.trunc(respuesta.data.ventas - respuesta.data.compras)
        )}`,
        icon: "success",
        button: true,
      });
      dispatch({
        type: TOTAL_VENTAS_COMPRAS_EVENTO,
        payload: respuesta,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const fnCleanStatusTotalComprasVentasEventoConsultar = () => {
    dispatch({
      type: CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR,
    });
  };

  const fnCleanStatusTotalComprasVentasEvento = () => {
    dispatch({
      type: CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO,
    });
  };

  const fnActivarOrdencompra = () => {
    dispatch({
      type: ACTIVAR_ORDEN_COMPRA,
    });
  };

  const fndesactivarOrdencompra = () => {
    dispatch({
      type: DESACTIVAR_ORDEN_COMPRA,
    });
  };

  const cleanGenerarPresupuesto = () => {
    dispatch({
      type: CLEAN_GENERAR_REPORTE_PROPUESTA,
    });
  };

  const fnCleanStatusOrdenesEvento = () => {
    dispatch({
      type: CLEAN_STATUS_ORDENES_EVENTO,
    });
  };

  const cleanGenerarOrden = () => {
    dispatch({
      type: CLEAN_GENERAR_REPORTE_ORDEN,
    });
  };

  const getEvent = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/eventos");
      dispatch({
        type: LISTADO_EVENTOS,
        payload: respuesta.data,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const postEvent = (info) => {
    return new Promise(async (resolver, reject) => {
      try {
        let payload = {
          nombre: info.nombre,
          direccion: info.direccion,
          servicio: info.servicio,
          estado: info.estado,
          region: info.region,
          comuna: info.comuna,
          observacionCliente: info.descripcion,
          fPresupuesto: info.fPresupuesto,
          fPago: info.fPago,
          estadoPago: info.estadoPago,
          tipoFact: info.tipoFact,
          nFactura: info.nFactura,
          nOrdenCompra: info.nOrdenCompra,
          formaPago: info.formaPago,
          metodoPago: info.metodoPago,
          fTermino: info.fTermino,
          cm: info.cm,
          // cliente_evento: 0,
        };
        const respuesta = await clienteAxios.post("/api/eventos", payload);
        getEvent();
        swal({
          title: `Evento Creado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putEvent = (payload, userUpdate) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.put(
          "/api/eventos/" + payload.id,
          payload
        );
        swal({
          title: userUpdate ? "Cliente Actualizado" : `Evento Actualizado`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        getEvent();
        resolver(respuesta);
      } catch (e) {
        reject(e);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putTotalEvento = async (datos) => {
    try {
      await clienteAxios.put(`/api/eventos/${datos.id}`, {
        totalCobrado: datos.totalCobrado,
      });
      swal({
        title: "Monto Total del Proyecto Informado Correctamente",
        text: "Esta mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      getEvent();
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const deleteEvent = (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/eventos/${id}`);
          swal({
            title: `Evento Eliminado`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          getEvent();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Esta mensaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Error al eliminar evento`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  const getComentarios = async (id) => {
    try {
      await clienteAxios.get("/api/comentarios/" + id);
      /*dispatch({
              type: LISTADO_EVENTOS,
              payload: respuesta.data,
          });*/
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const getClientes = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/clientes");
      dispatch({
        type: LISTADO_CLIENTES,
        payload: respuesta.data,
      });
      return respuesta.data;
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const postCliente = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post("api/clientes", payload);
        getClientes();
        swal({
          title: `Cliente agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putCliente = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.put(
          "/api/clientes/" + payload.id,
          payload
        );
        getClientes();
        swal({
          title: `Cliente Actualizado`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const deleteCliente = (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/clientes/${id}`);
          swal({
            title: `Cliente Eliminado`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          getClientes();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Esta mensaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Error al eliminar Cliente`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  const FilterProveedorRepeat = (Providers) => {
    return new Promise(async (resolver, reject) => {
      try {
        let ProvidersFinal = [];
        for (let i = 0; i < Providers.data.length; i++) {
          if (ProvidersFinal.length > 0) {
            let index = ProvidersFinal.findIndex(
              (provider) => provider.id === Providers.data[i].id
            );
            if (index === -1) {
              ProvidersFinal.push(Providers.data[i]);
            }
          } else {
            ProvidersFinal.push(Providers.data[i]);
          }
        }
        resolver(ProvidersFinal);
      } catch (e) {
        reject(e);
      }
    });
  };

  const getProveedoresForIdEvent = async (id) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.get(
          "/api/proveedoresEvento/" + id
        );
        let ProviderFinal = await FilterProveedorRepeat(respuesta);
        resolver(ProviderFinal);
        dispatch({
          type: PROVEEDORES_EVENTO,
          payload: ProviderFinal,
          status: respuesta.status,
        });
        fnGetOrdenesEvento(id);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const fnCleanStatusGetProveedoresForIdEvent = () => {
    dispatch({
      type: CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT,
    });
  };

  const getProveedores = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/proveedores");
      dispatch({
        type: LISTADO_PROOVEDORES,
        payload: respuesta.data,
      });
      return respuesta.data;
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const postProveedor = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        await clienteAxios.post("/api/proveedores", payload);
        getProveedores();
        swal({
          title: `Proveedor agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(true);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putProovedor = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.put(
          "/api/proveedores/" + payload.id,
          payload
        );
        getProveedores();
        swal({
          title: `Proveedor Actualizado`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const deleteProveedor = (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/proveedores/${id}`);
          swal({
            title: `Proveedor Eliminado`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          getProveedores();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Esta mensaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Error al eliminar Proveedor`,
            text: "Esta mensaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  const postLigarProveedor = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/ligarProveedor",
          payload
        );
        swal({
          title: `Proveedor agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
        reject(false);
      }
    });
  };

  const deleteDesLigarProveedor = (id) => {
    return new Promise(async (resolver, reject) => {
      swal({
        title: `¿Está seguro?`,
        text:
          "¡Una vez desvinculado, no podrá recuperar los datos de orden de compra y seguimiento del proveedor en el evento!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Desvincular",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await clienteAxios.delete("/api/desligarProveedor/" + id);
            swal({
              title: `Proveedor Desvinculado`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "success",
              timer: 2000,
              button: false,
            });
            getEvent();
          } else {
            swal({
              title: "¡Operación cancelada!",
              text: "Esta mensaje se cerrará automáticamente",
              timer: 2000,
              button: false,
            });
          }

          resolver(true);
        })
        .catch((error) => {
          if (error) {
            reject(false);
            swal({
              title: `Error al desvicular proveedor`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "error",
              timer: 2000,
              button: false,
            });
          } else {
            swal.stopLoading();
            swal.close();
          }
        });
    });
  };

  const getContabilidad = async () => {
    try {
      //const respuesta = await clienteAxios.get("/api/contabilidad");
      /*dispatch({
                type: CONTABILIDAD,
                payload: respuesta.data,
            });*/
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const getModulosForIdEvent = async (id) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.get("/api/modulosEvento/" + id);
        resolver(respuesta.data);
        dispatch({
          type: MODULOS_EVENTO,
          payload: respuesta,
        });
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const cleanStatusModulosEvento = () => {
    dispatch({
      type: CLEAN_STATUS_MODULOS,
    });
  };

  const cleanStatusModulosOrden = () => {
    dispatch({
      type: CLEAN_STATUS_MODULOS_ORDEN,
    });
  };

  const postModuloInEvento = async (data) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post("/api/modulos", data);
        swal({
          title: `Modulo agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putModuloInEvento = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let data = {
          descripcion: payload.descripcion,
          valor: payload.valor,
          cantidad: payload.cantidad,
          modulos_evento: payload.modulos_evento,
        };
        const respuesta = await clienteAxios.put(
          "/api/modulos/" + payload.id,
          data
        );
        swal({
          title: `Modulo Editado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const deleteModuloInEvento = async (id) => {
    return new Promise(async (resolver, reject) => {
      swal({
        title: "¿Está seguro?",
        text: "¡Una vez eliminado, no podra recuperar los datos!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Eliminar",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await clienteAxios.delete(`/api/modulos/${id}`);
            swal({
              title: `Modulo Eliminado`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "success",
              timer: 2000,
              button: false,
            });
            resolver(true);
          } else {
            swal({
              title: "¡Operación cancelada!",
              text: "Esta mensaje se cerrará automáticamente",
              timer: 2000,
              button: false,
            });
            // reject(false);
          }
        })
        .catch((error) => {
          if (error) {
            swal({
              title: `Error al eliminar Modulo`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "error",
              timer: 2000,
              button: false,
            });
            reject(false);
          } else {
            swal.stopLoading();
            swal.close();
            reject(false);
          }
        });
    });
  };

  const getComentarioForIdEvent = async (id) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.get(
          "/api/comentariosEvento/" + id
        );
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const getComentarioClientInEvent = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let idEvento = payload.idEvento;
        let idCliente = payload.idCliente;
        const respuesta = await clienteAxios.get(
          "/api/comentariosCliente/" + idEvento + "/" + idCliente
        );
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const getComentarioProviderInEvent = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let idEvento = payload.idEvento;
        let idProveedor = payload.idProveedor;
        const respuesta = await clienteAxios.get(
          "/api/comentariosProveedor/" + idEvento + "/" + idProveedor
        );
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const postComentarioInEvento = async (data) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post("/api/comentarios", data);
        swal({
          title: `Comentario agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putComentarioInEvento = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let data;
        if (payload.comentarios_cliente) {
          data = {
            descripcion: payload.descripcion,
            personal: payload.personal,
            comentarios_evento: payload.comentarios_evento,
            comentarios_cliente: payload.comentarios_cliente,
          };
        } else {
          if (payload.comentarios_proveedor) {
            data = {
              descripcion: payload.descripcion,
              personal: payload.personal,
              comentarios_evento: payload.comentarios_evento,
              comentarios_proveedor: payload.comentarios_proveedor,
            };
          } else {
            data = {
              descripcion: payload.descripcion,
              personal: payload.personal,
              comentarios_evento: payload.comentarios_evento,
            };
          }
        }

        const respuesta = await clienteAxios.put(
          "/api/comentarios/" + payload.id,
          data
        );
        swal({
          title: `Comentario Editado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const deleteComentario = async (id) => {
    return new Promise(async (resolver, reject) => {
      swal({
        title: "¿Está seguro?",
        text: "¡Una vez eliminado, no podra recuperar los datos!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Eliminar",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await clienteAxios.delete(`/api/comentarios/${id}`);
            swal({
              title: `Comentario Eliminado`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "success",
              timer: 2000,
              button: false,
            });
            resolver(true);
          } else {
            swal({
              title: "¡Operación cancelada!",
              text: "Esta mensaje se cerrará automáticamente",
              timer: 2000,
              button: false,
            });
            reject(false);
          }
        })
        .catch((error) => {
          if (error) {
            swal({
              title: `Error al eliminar Comentario`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "error",
              timer: 2000,
              button: false,
            });
            reject(false);
          } else {
            swal.stopLoading();
            swal.close();
            reject(false);
          }
        });
    });
  };

  const compraVentasEventosForDate = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let inicio = payload.inicio;
        let fin = payload.fin;
        const respuesta = await clienteAxios.get(
          "/api/comprasVentasEventosFecha/" + inicio + "/" + fin
        );
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const getEventoForDate = async (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let inicio = payload.inicio;
        let fin = payload.fin;
        const respuesta = await clienteAxios.get(
          "/api/getEventosFecha/" + inicio + "/" + fin
        );
        const respuesta2 = await clienteAxios.get(
          "/api/getOrdenesFecha/" + inicio + "/" + fin
        );
        resolver(respuesta.data);
        dispatch({
          type: EVENTOS_POR_FECHA,
          payload: respuesta.data,
          payload2: respuesta2.data,
        });
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const fnGetOrdenesEvento = async (idEvento) => {
    try {
      const respuesta = await clienteAxios.get("/api/ordenes/" + idEvento);
      dispatch({
        type: ORDENES_EVENTO,
        payload: respuesta.data,
        status: respuesta.status,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const getOrder = (idEvento) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.get("/api/ordenes/" + idEvento);
        resolver(respuesta.data);
        dispatch({
          type: DATOS_ORDEN,
          payload: respuesta,
        });
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putOrder = async (datos) => {
    try {
      const respuesta = await clienteAxios.put(
        `/api/ordenes/${datos.id}`,
        datos
      );
      swal({
        title: `Orden de Compra Modificada Correctamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const putEstadoPagoOrden = async (id, nuevoEstado, payloadFecha) => {
    try {
      const respuesta = await clienteAxios.put(`/api/ordenes/${id}`, {
        estadoPago: nuevoEstado.nuevoEstado,
      });
      getEventoForDate(payloadFecha);
      swal({
        title: `Estado de Pago Modificado Correctamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const putEstadoPagoPresupuesto = async (id, nuevoEstado, payloadFecha) => {
    try {
      const respuesta = await clienteAxios.put(`/api/eventos/${id}`, {
        estadoPago: nuevoEstado.nuevoEstado,
      });
      getEventoForDate(payloadFecha);
      swal({
        title: `Estado de Pago Modificado Correctamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const putTotalOrden = async (datos) => {
    try {
      const respuesta = await clienteAxios.put(`/api/ordenes/${datos.id}`, {
        totalPagado: datos.totalPagado,
      });
      swal({
        title: "Monto Total de la Orden de Compra Informada Correctamente",
        text: "Esta mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  const createOrden = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post("/api/ordenes", payload);
        swal({
          title: `Orden Creada Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const getModuloOrderProvider = (payload) => {
    return new Promise(async (resolver, reject) => {
      try {
        let idOrden = payload.idOrden;
        let idProveedor = payload.idProveedor;
        const respuesta = await clienteAxios.get(
          "/api/moduloOrden/" + idOrden + "/" + idProveedor
        );
        resolver(respuesta.data);
        dispatch({
          type: MODULOS_ORDEN_PROVEEDOR_EVENTO,
          payload: respuesta.data,
          statusModuloOrden: respuesta.status,
        });
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const createModuloOrden = (data) => {
    return new Promise(async (resolver, reject) => {
      try {
        const respuesta = await clienteAxios.post("/api/moduloOrden", data);
        swal({
          title: `Modulo agregado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const putModuloOrden = (data) => {
    return new Promise(async (resolver, reject) => {
      try {
        let payload = {
          descripcion: data.descripcion,
          valor: data.valor,
          cantidad: data.cantidad,
          Modulo_Orden_orden: data.Modulo_Orden_orden,
          Modulo_Orden_proveedor: data.Modulo_Orden_proveedor,
        };
        const respuesta = await clienteAxios.put(
          "/api/moduloOrden/" + data.id,
          payload
        );
        swal({
          title: `Modulo editado Correctamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "success",
          timer: 2000,
          button: false,
        });
        resolver(respuesta.data);
      } catch (e) {
        reject(false);
        swal({
          title: `Hubo un error, por favor, intente nuevamente`,
          text: "Esta mensaje se cerrará automáticamente",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const deleteModuloOrden = (id) => {
    return new Promise(async (resolver, reject) => {
      swal({
        title: "¿Está seguro?",
        text: "¡Una vez eliminado, no podra recuperar los datos!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Eliminar",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await clienteAxios.delete(`/api/moduloOrden/${id}`);
            swal({
              title: `Modulo Eliminado`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "success",
              timer: 2000,
              button: false,
            });
            resolver(true);
          } else {
            swal({
              title: "¡Operación cancelada!",
              text: "Esta mensaje se cerrará automáticamente",
              timer: 2000,
              button: false,
            });
            reject(false);
          }
        })
        .catch((error) => {
          if (error) {
            swal({
              title: `Error al eliminar Comentario`,
              text: "Esta mensaje se cerrará automáticamente",
              icon: "error",
              timer: 2000,
              button: false,
            });
            reject(false);
          } else {
            swal.stopLoading();
            swal.close();
            reject(false);
          }
        });
    });
  };

  return (
    <ProyectoContext.Provider
      value={{
        eventos: state.eventos,
        clientes: state.clientes,
        proveedores: state.proveedores,
        contabilidad: state.contabilidad,
        modulosEvento: state.modulosEvento,
        modulosOrden: state.modulosOrden,
        stGenerarPresupuesto: state.stGenerarPresupuesto,
        stGenerarOrden: state.stGenerarOrden,
        eventosPorFecha: state.eventosPorFecha,
        ordenesPorFecha: state.ordenesPorFecha,
        modulosProveedorEvento: state.modulosProveedorEvento,
        statusModuloOrden: state.statusModuloOrden,
        datosOrden: state.datosOrden,
        stActivarOrdenCompra: state.stActivarOrdenCompra,
        datosLecturaOrden: state.datosLecturaOrden,
        stComprasVentasEventos: state.stComprasVentasEventos,
        stStatusComprasVentas: state.stStatusComprasVentas,
        stTotalComprasVentasEvento: state.stTotalComprasVentasEvento,
        stProveedoresEvento: state.stProveedoresEvento,
        stOrdenesEvento: state.stOrdenesEvento,
        stStatusTotalIngEgEvento: state.stStatusTotalIngEgEvento,
        stStatusfnGetProveedoresForIdEvent:
          state.stStatusfnGetProveedoresForIdEvent,
        stStatusfnGetOrdenesEvento: state.stStatusfnGetOrdenesEvento,
        fnGetOrdenesEvento,
        fnTotalComprasVentasEvento,
        putTotalOrden,
        fnActivarOrdencompra,
        fndesactivarOrdencompra,
        postModuloInEvento,
        putModuloInEvento,
        deleteModuloInEvento,
        getComentarios,
        getEvent,
        postEvent,
        putEvent,
        deleteEvent,
        getClientes,
        postCliente,
        putCliente,
        deleteCliente,
        getProveedores,
        postProveedor,
        putProovedor,
        deleteProveedor,
        getContabilidad,
        postComentarioInEvento,
        putComentarioInEvento,
        deleteComentario,
        postLigarProveedor,
        deleteDesLigarProveedor,
        getProveedoresForIdEvent,
        getModulosForIdEvent,
        getComentarioForIdEvent,
        getComentarioProviderInEvent,
        getComentarioClientInEvent,
        compraVentasEventosForDate,
        getEventoForDate,
        putEstadoPagoOrden,
        putEstadoPagoPresupuesto,
        getOrder,
        createModuloOrden,
        getModuloOrderProvider,
        putModuloOrden,
        deleteModuloOrden,
        createOrden,
        cleanStatusModulosEvento,
        flagGenerarPresupuesto,
        cleanGenerarPresupuesto,
        cleanGenerarOrden,
        cleanStatusModulosOrden,
        putTotalEvento,
        datosOrdenGlobal,
        putOrder,
        fnComprasVentasEventos,
        fnCleanStatusComprasVentasEventos,
        fnCleanStatusTotalComprasVentasEventoConsultar,
        fnCleanStatusOrdenesEvento,
        fnCleanStatusGetProveedoresForIdEvent,
        fnCleanStatusTotalComprasVentasEvento,
      }}
    >
      {props.children}
    </ProyectoContext.Provider>
  );
};

export default ProyectoState;
