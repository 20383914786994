import React, { useReducer } from "react";
import generalReducer from "./generalReducer";
import generalContext from "./generalContext";

import {
  SHOW_ACTIONS_INVENTORY,
  HIDE_ACTIONS_INVENTORY,
  RESET_DASBOARD,
  PREGUNTA_REPORTE,
  FILAS_POR_PAGINA,
  CANTIDAD_PAGINAS,
  PAGINA_ACTUAL,
  TEXTO_BUSCAR,
  CLEAN_PAGINA_ACTUAL,
  RUTA_ACTUAL,
  CERRAR_MODAL,
  ABRIR_MODAL,
} from "../../types";

const GeneralState = (props) => {
  const initialState = {
    showactionsinventory: false,
    hideactionsinventory: true,
    preguntareporte: false,
    filasporpagina: 10,
    cantidadpaginas: 1,
    paginaseleccionada: 1,
    textobuscar: "",
    rutaactualglobal: null,
    estadoModal: false,
  };

  const [state, dispatch] = useReducer(generalReducer, initialState);

  // Funciones
  // DASHBOARD
  const showActionsInventory = () => {
    dispatch({
      type: SHOW_ACTIONS_INVENTORY,
    });
  };

  const hideActionsInventory = () => {
    dispatch({
      type: HIDE_ACTIONS_INVENTORY,
    });
  };

  const resetDasboard = () => {
    dispatch({
      type: RESET_DASBOARD,
    });
  };

  const preguntaReporte = () => {
    dispatch({
      type: PREGUNTA_REPORTE,
    });
  };
  // FIN DASHBOARD

  // PAGINACION
  const filasPorPagina = (filas) => {
    dispatch({
      type: FILAS_POR_PAGINA,
      payload: filas,
    });
  };

  const paginaSeleccionada = (pagina) => {
    dispatch({
      type: PAGINA_ACTUAL,
      payload: pagina,
    });
  };

  const cleanPaginaSeleccionada = () => {
    dispatch({
      type: CLEAN_PAGINA_ACTUAL,
    });
  };

  const cantidadPaginas = (paginas) => {
    dispatch({
      type: CANTIDAD_PAGINAS,
      payload: paginas,
    });
  };

  const textoBuscar = (texto) => {
    dispatch({
      type: TEXTO_BUSCAR,
      payload: texto,
    });
  };

  const rutaActualGlobal = (ruta) => {
    dispatch({
      type: RUTA_ACTUAL,
      payload: ruta,
    });
  };

  const abrirModal = () => {
    dispatch({
      type: ABRIR_MODAL,
    });
  };
  const cerrarModal = () => {
    dispatch({
      type: CERRAR_MODAL,
    });
  };

  return (
    <generalContext.Provider
      value={{
        showactionsinventory: state.showactionsinventory,
        hideactionsinventory: state.hideactionsinventory,
        preguntareporte: state.preguntareporte,
        filasporpagina: state.filasporpagina,
        cantidadpaginas: state.cantidadpaginas,
        paginaseleccionada: state.paginaseleccionada,
        textobuscar: state.textobuscar,
        rutaactualglobal: state.rutaactualglobal,
        estadoModal: state.estadoModal,
        showActionsInventory,
        hideActionsInventory,
        resetDasboard,
        preguntaReporte,
        filasPorPagina,
        cantidadPaginas,
        paginaSeleccionada,
        textoBuscar,
        cleanPaginaSeleccionada,
        rutaActualGlobal,
        cerrarModal,
        abrirModal,
      }}
    >
      {props.children}
    </generalContext.Provider>
  );
};

export default GeneralState;
