// GENERAL
import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import GAListener from "components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "components/Layout";
import componentQueries from "react-component-queries";
import PageSpinner from "components/PageSpinner";
import "./styles/reduction.scss";
// FIN GENERAL

// CONTEX
import AuthState from "./context/autenticacion/authState";
import tokenAuth from "./config/token";
import AlertaState from "./context/alertas/alertaState";
import GeneralState from "./context/general/generalState";
import UsuarioState from "./context/usuarios/usuarioState";
import ColegioState from "./context/colegios/colegioState";
import CodigoState from "./context/codigos/codigoState";
import ArticuloState from "./context/articulos/articuloState";
import PrestamoState from "./context/prestamos/prestamoState";
import ProyectoState from "./context/proyectos/proyectoState";
import ReporteState from "./context/reportes/reporteState";
// FIN CONTEX

// COMPONENTES
import Login from "./components/auth/Login";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Usuarios = React.lazy(() => import("./components/usuarios"));
const Establecimientos = React.lazy(() => import("./components/colegios"));
const Codigos = React.lazy(() => import("./components/codigos"));
const Articulos = React.lazy(() => import("./components/articulos"));
const Prestamos = React.lazy(() => import("./components/prestamos"));
const Perfil = React.lazy(() => import("components/cuenta"));
const Eventos = React.lazy(() => import("components/eventos"));
const Contabilidad = React.lazy(() => import("components/contabilidad"));
const ClientesProveedores = React.lazy(() =>
  import("components/clientesProveedores")
);
// FIN COMPONENTES

// REVISAR TOKEN
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}
// FIN REVISAR TOKEN

const App = (props) => {
  return (
    <AuthState>
      <AlertaState>
        <UsuarioState>
          <ColegioState>
            <ArticuloState>
              <PrestamoState>
                <CodigoState>
                  <GeneralState>
                    <ProyectoState>
                      <ReporteState>
                        <HashRouter>
                          <GAListener>
                            <Switch>
                              <LayoutRoute
                                exact
                                path="/"
                                layout={EmptyLayout}
                                component={(props) => <Login {...props} />}
                              />
                              <MainLayout breakpoint={props.breakpoint}>
                                <React.Suspense fallback={<PageSpinner />}>
                                  <Route
                                    exact
                                    path="/dashboard"
                                    render={(props) => <Dashboard {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/usuarios"
                                    render={(props) => <Usuarios {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/sucursales"
                                    render={(props) => (
                                      <Establecimientos {...props} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path="/eventos"
                                    render={(props) => <Eventos {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/clientesProveedores"
                                    render={(props) => (
                                      <ClientesProveedores {...props} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path="/contabilidad"
                                    render={(props) => (
                                      <Contabilidad {...props} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path="/inventario"
                                    render={(props) => <Articulos {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/prestamos"
                                    render={(props) => <Prestamos {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/codigos"
                                    render={(props) => <Codigos {...props} />}
                                  />
                                  <Route
                                    exact
                                    path="/perfil"
                                    render={(props) => <Perfil {...props} />}
                                  />
                                </React.Suspense>
                              </MainLayout>
                              <Redirect to="/" />
                            </Switch>
                          </GAListener>
                        </HashRouter>
                      </ReporteState>
                    </ProyectoState>
                  </GeneralState>
                </CodigoState>
              </PrestamoState>
            </ArticuloState>
          </ColegioState>
        </UsuarioState>
      </AlertaState>
    </AuthState>
  );
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
