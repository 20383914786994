import React, { useReducer } from "react";
import AuthReducer from "./authReducer";
import AuthContext from "./authContext";
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";
import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  ACTIVAR_CUENTA,
  OBTENER_USUARIO,
  CERRAR_SESION,
  CLEAN_STATUS,
  CLEAN_MENSAJE_ERROR,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    mensaje: null,
    cargando: true,
    status: null,
    cleanStatus: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Funciones

  // Iniciar Sesión
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/login", datos);
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
        daolyap: respuesta,
      });
      // Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      const alerta = {
        msg: 'usuario o contraseña no válidos',
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  const cleanStatusFunc = async () => {
    dispatch({
      type: CLEAN_STATUS,
    });
  };

  const clearMensaje = async () => {
    dispatch({
      type: CLEAN_MENSAJE_ERROR,
    });
  };

  // Activar cuenta
  const activarCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/activar", datos);
      dispatch({
        type: ACTIVAR_CUENTA,
        payload: respuesta.data,
      });
      // Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      const alerta = {
        msg: error,
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  // Retornar Usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    try {
      const respuesta = await clienteAxios.post("/api/verify");
      if (respuesta.status === 200) {
        await localStorage.setItem(
          "usuario",
          JSON.stringify(respuesta.data.data)
        );
        dispatch({
          type: OBTENER_USUARIO,
          payload: respuesta.data.data,
        });
      } else if (respuesta.status === 201 && respuesta.data.token !== "") {
        await localStorage.setItem("token", respuesta.data.token);
        dispatch({
          type: OBTENER_USUARIO,
          payload: respuesta.data,
        });
      }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        cargando: state.cargando,
        mensaje: state.mensaje,
        daolyap: state.daolyap,
        cleanStatus: state.cleanStatus,
        iniciarSesion,
        usuarioAutenticado,
        activarCuenta,
        cerrarSesion,
        cleanStatusFunc,
        clearMensaje,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
