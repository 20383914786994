import {
  FORMULARIO_COLEGIO,
  OBTENER_COLEGIO,
  REGISTRAR_COLEGIO,
  VALIDAR_FORMULARIO_COLEGIO,
  ELIMINAR_COLEGIO,
  COLEGIO_ERROR,
  ACTUALIZAR_COLEGIO,
  COLEGIO_ACTUAL,
  COLEGIO_DETALLES,
  COLEGIOS_USUARIO,
  DETALLES_COLEGIO_SELECCIONADO,
  LIMPIAR_COLEGIOS,
  COLEGIO_INVENTARIO,
  NOMBRE_U_EDUCATIVA,
  OCULTAR_ACCIONES,
  CLEAN_STATUS_INVENTARIO,
  FILTRAR_ARTICULOS,
  COLEGIO_INVENTARIO_DISPONIBLE,
  ABRIR_MODAL,
  CERRAR_MODAL,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_COLEGIO:
      return {
        ...state,
        formulario: true,
      };
    case OBTENER_COLEGIO:
      return {
        ...state,
        colegios: action.payload,
      };
    case COLEGIOS_USUARIO:
      return {
        ...state,
        colegiosusuario: action.payload,
      };
    case COLEGIO_INVENTARIO:
      return {
        ...state,
        inventariocolegio: action.payload.data,
        statusinventario: action.payload.status,
      };

    case COLEGIO_INVENTARIO_DISPONIBLE:
      return {
        ...state,
        inventariocolegiodisponible: action.payload.data,
        statusinventariodisponible: action.payload.status,
      };
    case FILTRAR_ARTICULOS:
      var total = action.payload.total;
      var nuevoObjeto = {
        total: parseInt(total),
        data: action.payload.articulos,
      };

      return {
        ...state,
        inventariocolegio: nuevoObjeto,
        statusFiltro: action.statusFiltro,
      };
    case CLEAN_STATUS_INVENTARIO:
      return {
        ...state,
        statusinventario: null,
      };
    case REGISTRAR_COLEGIO:
      return {
        ...state,
        colegios: [...state.colegios, action.payload.data],
        formulario: false,
        errorformulario: false,
        statusRespuesta: action.payload.status,
      };
    case OCULTAR_ACCIONES:
      return {
        ...state,
        mostrarendash: false,
      };
    case VALIDAR_FORMULARIO_COLEGIO:
      return {
        ...state,
        errorformulario: true,
      };
    case ELIMINAR_COLEGIO:
      return {
        ...state,
        colegios: state.colegios.filter(
          (colegio) => colegio.id !== action.payload
        ),
      };
    case ACTUALIZAR_COLEGIO:
      return {
        ...state,
        colegios: state.colegios.map((colegio) =>
          colegio.id === action.payload.id ? action.payload : colegio
        ),
        estadoModal: true,
        statusRespuesta: action.payload.status,
      };
    case COLEGIO_ACTUAL:
      return {
        ...state,
        colegioSeleccionado: action.payload,
      };
    case COLEGIO_DETALLES:
      return {
        ...state,
        colegioDetalles: action.payload,
      };

    case DETALLES_COLEGIO_SELECCIONADO:
      return {
        ...state,
        detallescolegioseleccionado: action.payload,
      };

    case NOMBRE_U_EDUCATIVA:
      return {
        ...state,
        nombresuducativa: action.payload,
      };

    case COLEGIO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    case LIMPIAR_COLEGIOS:
      localStorage.removeItem("token");
      return {
        ...state,
        colegios: [],
        colegiosusuario: [],
        formulario: false,
        errorformulario: false,
        colegio: null,
        colegioSeleccionado: null,
        colegioDetalles: null,
        detallescolegioseleccionado: null,
      };

    case ABRIR_MODAL:
      return {
        ...state,
        estadoModal: true,
        tipoModal:
          action.payload === "tipoAgregar"
            ? "tipoAgregar"
            : action.payload === "tipoDetalles"
            ? "tipoDetalles"
            : action.payload === "tipoEditar"
            ? "tipoEditar"
            : null,
        statusRespuesta: null,
      };

    case CERRAR_MODAL:
      return {
        ...state,
        estadoModal: false,
        statusRespuesta: null,
        tipoModal: null,
      };
    default:
      return state;
  }
};
