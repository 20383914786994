// LOGIN
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const ACTIVAR_CUENTA = "ACTIVAR_CUENTA";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const CLEAN_MENSAJE_ERROR = "CLEAN_MENSAJE_ERROR";

// USUARIOS
export const CREAR_CUENTA = "CREAR_CUENTA";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const USUARIO_ERROR = "USUARIO_ERROR";
export const REGISTRAR_USUARIO = "REGISTRAR_USUARIO";
export const VALIDAR_FORMULARIO = "VALIDAR_FORMULARIO";
export const FORMULARIO_USUARIO = "FORMULARIO_USUARIO";
export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO";
export const ACTUALIZAR_USUARIO = "ACTUALIZAR_USUARIO";
export const USUARIO_ACTUAL = "USUARIO_ACTUAL";
export const ENVIAR_CONTRASEÑA = "ENVIAR_CONTRASEÑA";
export const VINCULAR_USUARIO_COLEGIOS = "VINCULAR_USUARIO_COLEGIOS";
export const DESVINCULAR_USUARIO_EMRPESAS = "DESVINCULAR_USUARIO_EMRPESAS";
export const DETALLES_USUARIO = "DETALLES_USUARIO";
export const MOFICAR_PERFIL = "MOFICAR_PERFIL";
export const CLEAN_STATUS = "CLEAN_STATUS";

// COLEGIOS
export const OBTENER_COLEGIO = "OBTENER_COLEGIO";
export const COLEGIO_ERROR = "COLEGIO_ERROR";
export const REGISTRAR_COLEGIO = "REGISTRAR_COLEGIO";
export const VALIDAR_FORMULARIO_COLEGIO = "VALIDAR_FORMULARIO_COLEGIO";
export const FORMULARIO_COLEGIO = "FORMULARIO_COLEGIO";
export const ELIMINAR_COLEGIO = "ELIMINAR_COLEGIO";
export const ACTUALIZAR_COLEGIO = "ACTUALIZAR_COLEGIO";
export const COLEGIO_ACTUAL = "COLEGIO_ACTUAL";
export const COLEGIO_DETALLES = "COLEGIO_DETALLES";
export const COLEGIOS_USUARIO = "COLEGIOS_USUARIO";
export const DETALLES_COLEGIO_SELECCIONADO = "DETALLES_COLEGIO_SELECCIONADO";
export const LIMPIAR_COLEGIOS = "LIMPIAR_COLEGIOS";
export const COLEGIO_INVENTARIO = "COLEGIO_INVENTARIO";
export const NOMBRE_U_EDUCATIVA = "NOMBRE_U_EDUCATIVA";
export const OCULTAR_ACCIONES = "OCULTAR_ACCIONES";
export const COLEGIO_INVENTARIO_DISPONIBLE = "COLEGIO_INVENTARIO_DISPONIBLE";

//ALERTAS
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";

// ARTICULOS
export const LISTADO_ARTICULOS = "LISTADO_ARTICULOS";
export const REGISTRAR_ARTICULO = "REGISTRAR_ARTICULO";
export const ARTICULO_ERROR = "ARTICULO_ERROR";
export const FORMULARIO_ARTICULO = "FORMULARIO_ARTICULO";
export const ELIMINAR_ARTICULO = "ELIMINAR_ARTICULO";
export const ARTICULO_ACTUAL = "ARTICULO_ACTUAL";
export const INVENTARIO_USUARIO = "INVENTARIO_USUARIO";
export const VINCULAR_ARTICULOS = "VINCULAR_ARTICULOS";
export const DESVINCULAR_ARTICULOS = "DESVINCULAR_ARTICULOS";
export const GENERAR_REPORTE_ARTICULO = "GENERAR_REPORTE_ARTICULO";
export const LIMPIAR_GENERAR_REPORTE_ARTICULO =
  "LIMPIAR_GENERAR_REPORTE_ARTICULO";
export const ARTICULOS_DISPONIBLES = "ARTICULOS_DISPONIBLES";
export const ACTUALIZAR_ARTICULO = "ACTUALIZAR_ARTICULO";
export const CLEAN_STATUS_ARTICULO = "CLEAN_STATUS_ARTICULO";
export const LIMPIAR_STATUS = "LIMPIAR_STATUS";
export const FILTRAR_ARTICULOS = "FILTRAR_ARTICULOS";
export const CLEAN_STATUS_FILTRO = "CLEAN_STATUS_FILTRO";
export const CLEAN_STATUS_INVENTARIO = "CLEAN_STATUS_INVENTARIO";
export const GENERAR_REPORTE_INVENTARIO = "GENERAR_REPORTE_INVENTARIO";
export const LIMPIAR_GENERAR_REPORTE_INVENTARIO =
  "LIMPIAR_GENERAR_REPORTE_INVENTARIO";

// PRESTAMOS
export const LISTADO_PRESTAMOS = "LISTADO_PRESTAMOS";
export const REGISTRAR_PRESTAMO = "REGISTRAR_PRESTAMO";
export const PRESTAMO_ERROR = "PRESTAMO_ERROR";
export const FORMULARIO_PRESTAMO = "FORMULARIO_PRESTAMO";
export const ELIMINAR_PRESTAMO = "ELIMINAR_PRESTAMO";
export const PRESTAMO_ACTUAL = "PRESTAMO_ACTUAL";
export const GENERAR_PDF_PRESTAMO = "GENERAR_PDF_PRESTAMO";
export const LIMPIAR_GENERAR_PRESTAMO = "LIMPIAR_GENERAR_PRESTAMO";
export const PRESTAMOS_ESTABLECIMIENTOS = "PRESTAMOS_ESTABLECIMIENTOS";
export const PDF_PRESTAMO_EXISTENTE = "PDF_PRESTAMO_EXISTENTE";

// CODIGOS
export const LISTADO_CODIGOS = "LISTADO_CODIGOS";
export const REGISTRAR_CODIGO = "REGISTRAR_CODIGO";
export const CODIGO_ERROR = "CODIGO_ERROR";
export const FORMULARIO_CODIGO = "FORMULARIO_CODIGO";
export const ELIMINAR_CODIGO = "ELIMINAR_CODIGO";
export const CODIGO_ACTUAL = "CODIGO_ACTUAL";
export const ACTUALIZAR_CODIGO = "ACTUALIZAR_CODIGO";
export const CLEAN_STATUS_RESPUESTA = "CLEAN_STATUS_RESPUESTA";

// GENERAL
export const SHOW_ACTIONS_INVENTORY = "SHOW_ACTIONS_INVENTORY";
export const HIDE_ACTIONS_INVENTORY = "HIDE_ACTIONS_INVENTORY";
export const RESET_DASBOARD = "RESET_DASBOARD";
export const PREGUNTA_REPORTE = "PREGUNTA_REPORTE";
export const FILAS_POR_PAGINA = "FILAS_POR_PAGINA";
export const CANTIDAD_PAGINAS = "CANTIDAD_PAGINAS";
export const PAGINA_ACTUAL = "PAGINA_ACTUAL";
export const TEXTO_BUSCAR = "TEXTO_BUSCAR";
export const CLEAN_PAGINA_ACTUAL = "CLEAN_PAGINA_ACTUAL";
export const RUTA_ACTUAL = "RUTA_ACTUAL";
export const CERRAR_MODAL = "CERRAR_MODAL";
export const ABRIR_MODAL = "ABRIR_MODAL";

// PROYECTOS
export const LISTADO_EVENTOS = "LISTADO_EVENTOS";
export const LISTADO_CLIENTES = "LISTADO_CLIENTES";
export const LISTADO_PROOVEDORES = "LISTADO_PROOVEDORES";
export const CONTABILIDAD = "CONTABILIDAD";
export const CLEAN_STATUS_MODULOS = "CLEAN_STATUS_MODULOS";
export const CLEAN_STATUS_MODULOS_ORDEN = "CLEAN_STATUS_MODULOS_ORDEN";
export const EVENTOS_POR_FECHA = "EVENTOS_POR_FECHA";
export const MODULOS_ORDEN_PROVEEDOR_EVENTO = "MODULOS_ORDEN_PROVEEDOR_EVENTO";
export const DATOS_ORDEN = "DATOS_ORDEN";
export const ACTIVAR_ORDEN_COMPRA = "ACTIVAR_ORDEN_COMPRA";
export const DESACTIVAR_ORDEN_COMPRA = "DESACTIVAR_ORDEN_COMPRA";
export const LEER_DATOS_OC = "LEER_DATOS_OC";
export const LECTURA_ORDEN_COMPRA = "LECTURA_ORDEN_COMPRA";
export const CLEAN_STATUS_COMPRAS_VENTAS = "CLEAN_STATUS_COMPRAS_VENTAS";
export const COMPRAS_VENTAS_EVENTOS = "COMPRAS_VENTAS_EVENTOS";
export const TOTAL_VENTAS_COMPRAS_EVENTO = "TOTAL_VENTAS_COMPRAS_EVENTO";
export const CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO =
  "CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO";
export const PROVEEDORES_EVENTO = PROVEEDORES_EVENTO;
export const CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR =
  "CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR";
export const ORDENES_EVENTO = "ORDENES_EVENTO";
export const CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT =
  "CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT";
export const CLEAN_STATUS_ORDENES_EVENTO = "CLEAN_STATUS_ORDENES_EVENTO";

// REPORTES
export const ORDEN_COMPRA_PDF = "ORDEN_COMPRA_PDF";
export const PRESUPUESTO_PDF = "PRESUPUESTO_PDF";
export const MODULOS_EVENTO = "MODULOS_EVENTO";
export const CLEAN_GENERAR_REPORTE_PROPUESTA =
  "CLEAN_GENERAR_REPORTE_PROPUESTA";
export const CLEAN_GENERAR_REPORTE_ORDEN = "CLEAN_GENERAR_REPORTE_ORDEN";
export const GENERAR_PDF_PROPUESTA = "GENERAR_PDF_PROPUESTA";
export const ACTIVAR_DESCARGA_ORDEN = "ACTIVAR_DESCARGA_ORDEN";
export const DESACTIVAR_DESCARGA_ORDEN = "DESACTIVAR_DESCARGA_ORDEN";
