import {
  CLEAN_GENERAR_REPORTE_ORDEN,
  CLEAN_GENERAR_REPORTE_PROPUESTA,
  GENERAR_PDF_PROPUESTA,
  ACTIVAR_DESCARGA_ORDEN,
  DESACTIVAR_DESCARGA_ORDEN,
} from "../../types";


export default (state, action) => {
  switch (action.type) {
    case GENERAR_PDF_PROPUESTA:
      return {
        ...state,
        stGenerarPresupuesto: true,
      };
    case CLEAN_GENERAR_REPORTE_PROPUESTA:
      return {
        ...state,
        stGenerarPresupuesto: false,
      };

    case CLEAN_GENERAR_REPORTE_ORDEN:
      return {
        ...state,
        stGenerarOrden: false,
      };

      case ACTIVAR_DESCARGA_ORDEN:
        return {
          ...state,
          activarDescargaOrden: true,
        };

        case DESACTIVAR_DESCARGA_ORDEN:
        return {
          ...state,
          activarDescargaOrden: false,
        };

        
    default:
      return state;
  }
};
